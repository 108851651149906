import React, { useContext } from 'react';

import { Check } from 'lucide-react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import { Badge } from '_ui/_common/Badge';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { BadgeType } from 'utils/structureConstants';

const propTypes = {
  badges: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

const DUNSRegisteredSeal = ({ badges = [], className = '' }) => {
  const { getTranslated } = useContext(InternationalizationContext);

  if (!badges) {
    return null;
  }

  const getBadgeClasses = (type) => ({
    'text-duns-registered-text bg-duns-registered-background': type === 'duns_registered',
    'text-white bg-duns-credibility-background': type === 'duns_credibility',
    'text-white bg-orange-700': type === 'duns_credibility_premium',
    'text-white bg-gray-800': type === 'duns_100',
  });

  const renderDunsSeal = (type) => {
    const customClasses = twMerge(
      classNames('py-1 px-3.5 font-semibold rounded-[18px]', getBadgeClasses(type), {
        [className]: !isEmpty(className),
      }),
    );

    if (BadgeType[type]) {
      return (
        <Badge size="lg" className={customClasses}>
          <Check className="h-4 w-4 mr-2" />
          {getTranslated(BadgeType[type], 'title')}
        </Badge>
      );
    }

    return null;
  };

  return badges.map((badge) => renderDunsSeal(badge?.type));
};

DUNSRegisteredSeal.propTypes = propTypes;

export default DUNSRegisteredSeal;
