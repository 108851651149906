import React, { useContext } from 'react';

import { ArrowBigRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/ExploreByIndustry/ExploreByIndustry.module.scss';
import IndustryBox from 'components/IndustryBox';
import Link from 'components/Link';
import { useGetSectorIndustriesQuery } from 'store/api';

const MAX_EXPLORE_SECTOR_INDUSTRIES_ITEMS = 8;

const ExploreByIndustry = () => {
  const { t } = useTranslation('home');

  const { language, getTranslated } = useContext(InternationalizationContext);

  const { data: { results: sectors = [] } = { results: [] } } = useGetSectorIndustriesQuery({
    size: MAX_EXPLORE_SECTOR_INDUSTRIES_ITEMS,
    language, // Only for cache key purposes
  });

  const renderSectorIndustries = () =>
    sectors.map((sector) => (
      <IndustryBox
        key={getTranslated(sector, 'description')}
        name={getTranslated(sector, 'description')}
        href={`/search?industry=${sector.id}`}
      />
    ));

  const renderSectorIndustriesLoader = () => [1, 2, 3, 4, 5, 6, 7, 8].map((number) => <IndustryBox key={number} />);

  return (
    <div className="w-full">
      <div className={styles.title__industry}>{t('section_2.title')}</div>
      <Grid cols={1} md={3} lg={4} gap={6}>
        {sectors ? renderSectorIndustries() : renderSectorIndustriesLoader()}
      </Grid>
      <div className={styles.browse__button}>
        <Link
          href="/browse-industries"
          data-testid="explore_industries--link_button"
          className="group flex items-center text-lg font-semibold text-blue-600 hover:text-gray-300"
        >
          {t('section_2.button')}
          <ArrowBigRightIcon className="mt-0.5 ml-1 w-5 h-5 text-blue-600 group-hover:text-gray-300 scale-y-75 scale-x-150 fill-current" />
        </Link>
      </div>
    </div>
  );
};

export { ExploreByIndustry };
