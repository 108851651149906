import React from 'react';

import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import { ChevronRight } from 'lucide-react';

import Icon from 'components/Icon';
import Link from 'components/Link';

import styles from './IndustryBox.module.scss';

const propTypes = {
  name: PropTypes.string,
  href: PropTypes.string,
};

const IndustryBox = ({ name, href = '#' }) => (
  <div className={styles.container}>
    <Link
      href={href}
      className={classnames('rounded-xl', styles.container__border)}
      variant="dark"
      data-testid="industry_link"
    >
      <div className={styles.industry__name}>{name || <Skeleton />}</div>
      <div>
        <ChevronRight className={styles.industry__icon} />
      </div>
    </Link>
  </div>
);

IndustryBox.propTypes = propTypes;

export default IndustryBox;
