import React, { useContext } from 'react';

import { PlusIcon, Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '_ui/_common/Button';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/BusinessHomeSection/BusinessHomeSection.module.scss';

const BusinessHomeSection = () => {
  const { t } = useTranslation('home');
  const { domainLocationDisplayName } = useContext(InternationalizationContext);

  return (
    <Grid className={styles.box}>
      <h2 className={styles.box__title}>{t('section_3.title', { domainLocationDisplayName })}</h2>
      <p className={styles.box__text}>{t('section_3.text', { domainLocationDisplayName })}</p>
      <div className="flex flex-col sm:flex-row gap-4 justify-center items-center mb-14">
        <LinkButton href="/companies" data-testid="home--search--link_button">
          <Search className="w-4 h-4 mr-2" />
          {t('section_3.search_button')}
        </LinkButton>
        <LinkButton href="/list-my-business" variant="outlined" data-testid="home--list_my_business--link_button">
          <PlusIcon className="w-4 h-4 mr-2" />
          {t('section_3.plus_button')}
        </LinkButton>
      </div>
    </Grid>
  );
};

export default BusinessHomeSection;
