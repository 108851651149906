import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  Icon: PropTypes.object,
  data: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

const CompanyFeatures = ({ Icon, data, className, iconClassName = '', ...rest }) => {
  if (!data) return null;

  return (
    <span className={className}>
      <Icon className={classNames(iconClassName, 'w-4 h-4 text-gray-600')} />
      <span className="ml-2 text-sm text-gray-600" {...rest}>
        {data}
      </span>
    </span>
  );
};

CompanyFeatures.propTypes = propTypes;

export default CompanyFeatures;
